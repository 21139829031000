import React from 'react'

import { Select } from '../../../main/components'
import { Button } from '../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { Input } from '../../../main/components/input'
import { Label } from '../../../main/components/typography'
import { rangesToFilter } from '../utils'
import { FilterProps } from './types'

const DEFAULT_RANGE = { from: '', to: '' }

type PeriodRangeFilterProps = {
  periodOptions: { value: string; label: string }[]
  useOverUnderLabels?: boolean
} & FilterProps

export default function PeriodRangeFilter({
  onSubmit,
  config,
  periodOptions,
  useOverUnderLabels,
}: PeriodRangeFilterProps) {
  const [selectedPeriod, setSelectedPeriod] = React.useState<string>()
  const [periodRange, setPeriodRange] = React.useState(DEFAULT_RANGE)

  React.useEffect(() => {
    const filter = config.selectedValues[0]
    if (filter) {
      const delimiterIndex = filter.lastIndexOf('-')
      const period = filter.substring(delimiterIndex + 1)
      const range = filter.substring(0, delimiterIndex)

      if (!periodOptions.some(({ value }) => value === period)) {
        return
      }
      setSelectedPeriod(period)

      if (range) {
        const [from, to] = range.split('to')
        setPeriodRange({ from, to })
      }
    }
  }, [config, periodOptions])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit([selectedPeriod ? rangesToFilter([periodRange], [`-${selectedPeriod}`]) : null])
  }

  return (
    <>
      <DialogBody className="relative flex w-145 max-w-full flex-col justify-center">
        <form id={`${config.filter}-form`} onSubmit={handleSubmit}>
          <div className="date-range-body grid grid-cols-3 gap-3">
            <div className="date-picker-input-wrapper relative flex flex-1">
              <Label title="Period">
                <Select
                  aria-label="Period"
                  items={periodOptions}
                  value={selectedPeriod}
                  onChange={(item) => {
                    setSelectedPeriod(item.value)
                  }}
                />
              </Label>
            </div>
            <div className="date-picker-input-wrapper relative flex flex-1">
              <Input
                label={useOverUnderLabels ? 'Over' : 'Above'}
                type="number"
                value={periodRange.from}
                step="any"
                onChange={(e) => {
                  setPeriodRange({ ...periodRange, from: e.currentTarget.value })
                }}
              />
            </div>
            <div className="date-picker-input-wrapper relative flex flex-1">
              <Input
                label={useOverUnderLabels ? 'Under' : 'Below'}
                type="number"
                value={periodRange.to}
                step="any"
                onChange={(e) => {
                  setPeriodRange({ ...periodRange, to: e.currentTarget.value })
                }}
              />
            </div>
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form={`${config.filter}-form`} theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
